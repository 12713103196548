@tailwind base;
@tailwind components;
@tailwind utilities;

/* Config */
body {
  @apply bg-aredewhite;
}

h1 {
  @apply text-aredeblack;
  @apply font-black;
  @apply text-4xl;
}

h2 {
  @apply text-aredeblack;
  @apply font-semibold;
  @apply text-3xl;
}

h3 {
  @apply text-aredeblack;
  @apply font-semibold;
  @apply text-xl;
}

p,
ul,
li,
span {
  @apply text-aredegraytext;
}

label {
  @apply text-aredeblack;
  @apply text-sm;
}

input,
textarea,
select {
  @apply bg-aredegray;
  @apply text-aredeblack;
  @apply text-sm;
  @apply p-3;
  @apply border;
  @apply border-aredegrayborder;
  @apply rounded-3xl;
  @apply focus:outline-none;
  @apply focus:border;
  @apply focus:border-aredeblack;
  @apply mt-1;
  @apply placeholder:text-aredegraytext/40;
}

/* Tailwind Combined */
@layer components {
  .btn-primary {
    @apply relative;
    @apply inline-flex;
    @apply h-[45px];
    @apply min-w-[200px];
    @apply items-center;
    @apply justify-center;
    @apply overflow-hidden;
    @apply rounded-3xl;
    background: linear-gradient(180deg, #1f1f1f 0%, #1c1917 100%);
    box-shadow: 0px 3.99px 3.99px 0px rgba(8, 8, 8, 0.08),
      0px 1px 2px 0px rgba(8, 8, 8, 0.2),
      0px 5.99px 11.98px 0px rgba(255, 255, 255, 0.12) inset,
      0px 1px 1px 0px rgba(255, 255, 255, 0.2) inset;
    @apply hover:shadow-sm;
    @apply transition;
    @apply duration-200;
    @apply ease-in-out;
    @apply px-4;
    @apply font-normal;
    @apply text-aredewhite;
    @apply focus:outline-none;
  }

  .btn-secondary {
    @apply relative;
    @apply inline-flex;
    @apply h-[45px];
    @apply min-w-[150px];
    @apply items-center;
    @apply justify-center;
    @apply overflow-hidden;
    @apply rounded-3xl;
    @apply border;
    @apply border-aredegrayborder;
    @apply bg-aredegray;
    @apply hover:shadow-sm;
    @apply transition;
    @apply duration-200;
    @apply ease-in-out;
    @apply px-4;
    @apply font-normal;
    @apply text-aredeblack;
    @apply focus:outline-none;
  }

  .loading-spinner {
    @apply absolute;
    @apply left-1/2;
    @apply top-1/2;
    @apply h-5;
    @apply w-5;
    @apply -ml-2.5;
    @apply -mt-2.5;
    @apply border-2;
    @apply border-t-transparent;
    @apply border-aredegray;
    @apply rounded-full;
    @apply animate-spin;
  }

  .loading {
    @apply relative;
    @apply text-transparent;
    @apply pointer-events-none;
  }

  .loading .loading-spinner {
    @apply block;
  }
}

/* Gradients */
.gradient-background {
  background-image: linear-gradient(320.54deg, #00069f 0%, #120010 72.37%),
    linear-gradient(58.72deg, #69d200 0%, #970091 100%),
    linear-gradient(121.28deg, #8cff18 0%, #6c0075 100%),
    linear-gradient(121.28deg, #8000ff 0%, #000000 100%),
    linear-gradient(180deg, #00ff19 0%, #24ff00 0.01%, #2400ff 100%),
    linear-gradient(52.23deg, #0500ff 0%, #ff0000 100%),
    linear-gradient(121.28deg, #32003a 0%, #ff4040 100%),
    radial-gradient(50% 72.12% at 50% 50%, #eb00ff 0%, #110055 100%);
  background-blend-mode: screen, color-dodge, color-burn, screen, overlay,
    difference, color-dodge, normal;
}

/* Shadows */
.shadow-primary {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
}

.fade-in-up-animation {
  animation: fade-in-up 0.5s ease-in-out;
}

@keyframes fade-in-up {
  0% {
    transform: translateY(100%);
  }
}
